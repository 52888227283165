import { config } from "core/config";

import { ReactPlugin as AppInsightsReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

export const initAppInsights = () => {
  const appInsightPlugin = new AppInsightsReactPlugin();

  const appInsights = new ApplicationInsights({
    config: {
      enableAutoRouteTracking: false,
      disableFetchTracking: false,
      extensions: [appInsightPlugin as any],
      ...config.appInsights
    }
  });
  appInsights.loadAppInsights();
  return appInsightPlugin;
};
