import { useState } from "react";

import { Link, MessageBar, MessageBarType, Text } from "@bps/fluent-ui";

import { useLocationsQuery } from "../core/gateways/practice";

export const InfoBarRestriction = () => {
  const [showMessageBar, setShowMessageBar] = useState(true);
  const { data: locations } = useLocationsQuery();
  if (!showMessageBar) {
    return null;
  }

  const isLocationsDisabled = locations?.every(
    location => location.practiceCanTurnOnOnlineBooking === false
  );

  if (!isLocationsDisabled) {
    return null;
  }

  return (
    <MessageBar
      onDismiss={() => setShowMessageBar(false)}
      dismissButtonAriaLabel="close-info-bar-restriction"
      messageBarType={MessageBarType.warning}
    >
      <Text variant="small">
        Your account has been suspended and online bookings has been turned off.
        Please contact the Bp Sales team on
        <Link href="tel:130040111">1300 40 111 </Link> to re-activate your
        account.
      </Text>
    </MessageBar>
  );
};
