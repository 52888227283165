import { IEnum } from "modules/booking/shared/models/common.model";

export const NO_TAGS_REGEX = /(<([^>]+)>)/gi;

export class UtilService {
  public static getEnumDescription(type: IEnum, key: any): string {
    if (!type) return "";
    return type[key];
  }

  public static cleanTzName(name: string) {
    return name.replace(/_/g, " ");
  }

  public static getTextFromHtml(value?: string | null) {
    return (value ?? "").replace(NO_TAGS_REGEX, "").trim();
  }
}
