import {
  defaultConfirmationDialogButtonsText,
  FORM_PROMPT_RESET_MESSAGE
} from "common/ui-components/form/Form.types";
import { ReactNode } from "react";
import { useFormContext } from "react-hook-form";
import { FieldValues } from "react-hook-form/dist/types/fields";
import { FormState, UseFormReturn } from "react-hook-form/dist/types/form";

import {
  confirm,
  DefaultButton,
  IButtonProps,
  PrimaryButton,
  Spinner,
  SpinnerSize
} from "@bps/fluent-ui";

export interface FormSubmitCancelButtonsProps<FormValues extends FieldValues> {
  onCancel?: (form: UseFormReturn<FormValues>) => void;
  disableCancel?: boolean;
  disableSubmit?: (formState: FormState<FormValues>) => boolean;
  submitButtonProps?: IButtonProps;
  cancelButtonProps?: IButtonProps;
  submitDataTestAttr?: string;
  cancelDataTestAttr?: string;
  formId?: string;
  className?: string;
  onRenderSubmitCancelButtons?: (form: UseFormReturn<FormValues>) => ReactNode;
  extraActionsBefore?: (form: UseFormReturn<FormValues>) => ReactNode;
}

export const FormSubmitCancelButtons = <
  FormValues extends FieldValues = FieldValues
>({
  onCancel,
  disableSubmit,
  disableCancel,
  submitButtonProps,
  cancelButtonProps,
  submitDataTestAttr,
  cancelDataTestAttr,
  formId,
  className,
  onRenderSubmitCancelButtons,
  extraActionsBefore
}: FormSubmitCancelButtonsProps<FormValues>) => {
  const form = useFormContext<FormValues>();

  return (
    <div className={className}>
      {onRenderSubmitCancelButtons ? (
        onRenderSubmitCancelButtons(form)
      ) : (
        <>
          {extraActionsBefore ? extraActionsBefore(form) : null}
          <PrimaryButton
            type="submit"
            form={formId}
            data-test={submitDataTestAttr ?? "save-button"}
            text="Save"
            {...submitButtonProps}
            disabled={
              disableSubmit
                ? disableSubmit(form.formState)
                : form.formState.isSubmitting || !form.formState.isDirty
            }
            onRenderIcon={() =>
              form.formState.isSubmitting ? (
                <Spinner size={SpinnerSize.small} />
              ) : null
            }
          />

          <DefaultButton
            text="Cancel"
            data-test={cancelDataTestAttr ?? "cancel-button"}
            styles={{ root: { marginLeft: 16 } }}
            {...cancelButtonProps}
            disabled={disableCancel ?? !form.formState.isDirty}
            onClick={() => {
              if (onCancel) {
                onCancel(form);
              } else {
                confirm({
                  dialogContentProps: { subText: FORM_PROMPT_RESET_MESSAGE },
                  confirmButtonProps: {
                    text: defaultConfirmationDialogButtonsText.reset
                  },
                  cancelButtonProps: {
                    text: defaultConfirmationDialogButtonsText.cancel
                  }
                }).then(reset => {
                  if (reset) {
                    form.reset();
                  }
                });
              }
            }}
          />
        </>
      )}
    </div>
  );
};
