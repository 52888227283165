import { ErrorAlert } from "common/ui-components/Alert";
import { FunctionComponent, PropsWithChildren } from "react";
import { FallbackProps } from "react-error-boundary";

export const ErrorBoundaryFallback: FunctionComponent<
  PropsWithChildren<FallbackProps>
> = ({ error, children }) => {
  if (error) {
    return <ErrorAlert showReloadLink={true} />;
  }
  return <>{children}</>;
};
