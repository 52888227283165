import { config } from "core/config";
import { navBarActionButtonStyles } from "layout/NavBar";
import { observer } from "mobx-react-lite";

import {
  ActionButton,
  IIconProps,
  Persona,
  PersonaSize,
  useTheme
} from "@bps/fluent-ui";
import { logout } from "@bps/http-client";

import { useUserPreferencesStore } from "../StoresProvider";

interface IUserProfileMenuItem {
  key: string;
  iconProps: IIconProps;
  text: string;
  onClick: () => void;
}

export const UserProfile = observer(() => {
  const theme = useTheme();

  const {
    isProductionEnvironment,
    toggleFeatureToggle,
    areToggledFeaturesVisible
  } = useUserPreferencesStore();

  const handleLogout = () => {
    logout().then(() => {});
  };

  const handleFeatureToggle = async () => {
    toggleFeatureToggle();
  };

  const handleManageSubscription = () => {
    window.open(config.subscriptionUrl, "_blank");
  };

  const items: IUserProfileMenuItem[] = [];

  if (areToggledFeaturesVisible) {
    items.push({
      key: "manageSubscription",
      text: "Manage subscription",
      iconProps: {
        iconName: "NavigateExternalInline",
        styles: { root: { order: 1, marginBottom: "4px" } }
      },
      onClick: handleManageSubscription
    });
  }

  if (!isProductionEnvironment) {
    items.push({
      key: "featureToggle",
      iconProps: {
        iconName: areToggledFeaturesVisible ? "toggleRight" : "toggleLeft",
        styles: { root: { order: 1 } }
      },
      text: `Toggle features ${areToggledFeaturesVisible ? "off" : "on"}`,
      onClick: handleFeatureToggle
    });
  }

  items.push({
    key: "logout",
    text: "Logout",
    iconProps: {
      iconName: "",
      styles: { root: { order: 1 } }
    },
    onClick: handleLogout
  });

  return (
    <ActionButton
      data-test="Persona"
      menuProps={{
        shouldFocusOnMount: true,
        items
      }}
      styles={navBarActionButtonStyles(theme)}
    >
      <Persona
        styles={({ theme }) => ({
          primaryText: {
            color: theme.palette.white,
            selectors: {
              "&:hover": {
                color: theme.palette.white
              }
            }
          }
        })}
        text=""
        imageInitials=""
        size={PersonaSize.size24}
      />
    </ActionButton>
  );
});
