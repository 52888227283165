import { useGateways } from "core/gateways-context";
import { ProviderRefDataKeys } from "core/gateways/practice/ProviderGateway";
import {
  cacheUpdateItemArray,
  cacheUpsertSingleItemInArray
} from "core/gateways/ReactQueryCacheHelpers";
import { RefDataModel } from "core/models";
import { ProviderLocationsDto } from "core/models/licence";
import { QueryOptions } from "core/ReactQueryProvider";
import { guid } from "core/types/Guid";
import {
  ProviderAppointmentTypeDto,
  ProviderDto
} from "modules/booking/models";
import { AppointmentTypeAvailabilityDto } from "modules/booking/models/appointmentTypeAvailability.model";
import {
  PatientConfigRequestDto,
  PatientConfigResponseDto
} from "modules/booking/models/patientConfig.model";
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult
} from "react-query";

export const ProviderCacheKeys = {
  AppointmentTypeAvailability: "appointment-type-availabilities",
  LocationProviders: "location-providers",
  RefData: "ref-data",
  PatientConfig: "patient-config",
  ProviderAppointmentTypes: "provider-appointment-types",
  ProviderLocations: "provider-locations"
};

export const usePatientConfigQuery = (
  locationId: guid,
  providerId: guid,
  appointmentTypeId: guid
): UseQueryResult<PatientConfigResponseDto> => {
  const { providerApi } = useGateways();

  return useQuery(
    [
      ProviderCacheKeys.PatientConfig,
      locationId,
      providerId,
      appointmentTypeId
    ],
    async () =>
      await providerApi.getPatientConfig(
        providerId,
        locationId,
        appointmentTypeId
      )
  );
};

export const usePatientConfigMutation = () => {
  const queryClient = useQueryClient();
  const { providerApi } = useGateways();

  return useMutation<
    PatientConfigResponseDto,
    unknown,
    {
      locationId: string;
      providerId: string;
      appointmentTypeId: string;
      request: PatientConfigRequestDto;
    }
  >(
    ({ locationId, providerId, appointmentTypeId, request }) =>
      providerApi.updatePatientConfig({
        providerId,
        locationId,
        appointmentTypeId,
        request
      }),
    {
      onSuccess: (
        response: PatientConfigResponseDto,
        { locationId, providerId, appointmentTypeId }
      ) => {
        queryClient.setQueryData(
          [
            ProviderCacheKeys.PatientConfig,
            locationId,
            providerId,
            appointmentTypeId
          ],
          response
        );

        updateProviderandProviderList(locationId, providerId, queryClient);
      }
    }
  );
};

const updateProviderandProviderList = (
  locationId: string,
  providerId: string,
  queryClient: QueryClient
) => {
  // Refetch cache for provider appointment types table
  queryClient.refetchQueries([
    ProviderCacheKeys.ProviderAppointmentTypes,
    locationId,
    providerId
  ]);

  // Invalidate cache for providers table
  queryClient.invalidateQueries([
    ProviderCacheKeys.LocationProviders,
    locationId
  ]);
};

// Remove this customised appointment type from our patient config so we don't query for it
export const useRemovePatientConfig = () => {
  const queryClient = useQueryClient();
  return (locationId: guid, providerId: guid, appointmentTypeId: guid) => {
    queryClient.removeQueries([
      ProviderCacheKeys.PatientConfig,
      locationId,
      providerId,
      appointmentTypeId
    ]);
  };
};

export const useProviderLocationsQuery = <TResult = ProviderLocationsDto[]>(
  options?: QueryOptions<ProviderLocationsDto[], TResult>
) => {
  const { providerApi } = useGateways();
  return useQuery(
    [ProviderCacheKeys.ProviderLocations],
    providerApi.getProviderLocations,
    options
  );
};

export const useProvidersRefDataQuery = <TResult = Map<string, RefDataModel>>(
  options?: QueryOptions<Map<string, RefDataModel>, TResult>
) => {
  const { providerApi } = useGateways();
  return useQuery(
    [ProviderCacheKeys.RefData],
    async () => {
      const refDataMap = new Map<string, RefDataModel>();
      const keys = Object.keys(ProviderRefDataKeys);
      // Get ref data
      const data = await Promise.all(keys.map(x => providerApi.getRef(x)));

      // Map ref data
      data.forEach((d, idx) => {
        keys[idx] &&
          refDataMap.set(
            keys[idx],
            new RefDataModel(d) // adapt RefDataDto to RefDataModel to have some usefully properties
          );
      });

      return refDataMap;
    },
    { ...options, cacheTime: Infinity, staleTime: Infinity }
  );
};

export const useLocationProvidersQuery = <TResult = ProviderDto[]>(
  locationId: guid,
  options?: QueryOptions<ProviderDto[], TResult>
) => {
  const { providerApi } = useGateways();
  return useQuery(
    [ProviderCacheKeys.LocationProviders, locationId],
    async () => await providerApi.getProvidersForLocation(locationId),
    options
  );
};

export const useLocationProviderQuery = (
  locationId: guid,
  providerId?: guid
): UseQueryResult<ProviderDto | undefined> => {
  const options: QueryOptions<ProviderDto[], ProviderDto | undefined> = {
    select: (data: ProviderDto[]) => data.find(p => p.id === providerId)
  };

  return useLocationProvidersQuery<ProviderDto | undefined>(
    locationId,
    options
  );
};

export const useLocationProviderMutation = () => {
  const queryClient = useQueryClient();
  const { providerApi } = useGateways();

  return useMutation<
    ProviderDto,
    unknown,
    { provider: ProviderDto; locationId: guid }
  >(
    ({ provider, locationId }) =>
      providerApi.updateProviderForLocation({ ...provider, locationId }),
    {
      onSuccess: (provider: ProviderDto, { locationId }) => {
        cacheUpsertSingleItemInArray({
          queryClient,
          queryKey: [ProviderCacheKeys.LocationProviders, locationId],
          item: provider
        });
        // Refetch cache for provider appointment types table
        queryClient.refetchQueries(ProviderCacheKeys.ProviderAppointmentTypes);

        // Refetch patient config cache for default on/off state for existing and new patients.
        queryClient.refetchQueries(ProviderCacheKeys.PatientConfig);
      }
    }
  );
};

export const useUpdateProviderPhotoMutation = () => {
  const { providerApi } = useGateways();

  return useMutation<
    string,
    unknown,
    { locationId: string; providerId: string; file: File }
  >(({ locationId, providerId, file }) =>
    providerApi.uploadProviderPhoto(locationId, providerId, file)
  );
};

export const useAppointmentTypeAvailabilitiesQuery = (
  locationId: guid,
  providerId: guid,
  appointmentTypeId: guid
): UseQueryResult<AppointmentTypeAvailabilityDto[]> => {
  const { providerApi } = useGateways();
  return useQuery(
    [
      ProviderCacheKeys.AppointmentTypeAvailability,
      locationId,
      providerId,
      appointmentTypeId
    ],
    async () =>
      await providerApi.getAppointmentTypeAvailabilities(
        providerId,
        locationId,
        appointmentTypeId
      )
  );
};

export const useProviderAppointmentTypesQuery = (
  locationId: guid,
  providerId: guid
): UseQueryResult<ProviderAppointmentTypeDto[]> => {
  const { providerApi } = useGateways();

  return useQuery(
    [ProviderCacheKeys.ProviderAppointmentTypes, locationId, providerId],
    async () =>
      await providerApi.getAppointmentTypesForProvider(providerId, locationId)
  );
};

export const useCreateAppointmentTypeAvailabilityMutation = () => {
  const queryClient = useQueryClient();
  const { providerApi } = useGateways();

  return useMutation<
    AppointmentTypeAvailabilityDto,
    unknown,
    { availability: AppointmentTypeAvailabilityDto }
  >(
    ({ availability }) =>
      providerApi.createAppointmentTypeAvailability(availability),
    {
      onSuccess: (availability: AppointmentTypeAvailabilityDto) => {
        const { locationId, providerId, appointmentTypeId } = availability;
        updateProviderandProviderList(locationId, providerId, queryClient);
        queryClient.invalidateQueries(
          ProviderCacheKeys.AppointmentTypeAvailability
        );
        cacheUpsertSingleItemInArray({
          queryClient,
          queryKey: [
            ProviderCacheKeys.AppointmentTypeAvailability,
            locationId,
            providerId,
            appointmentTypeId
          ],
          item: availability
        });
      }
    }
  );
};

export const useDeleteAppointmentTypeAvailabilityMutation = (
  locationId: guid,
  providerId: guid,
  appointmentTypeId: guid
) => {
  const queryClient = useQueryClient();
  const { providerApi } = useGateways();

  return useMutation<
    boolean,
    unknown,
    { appointmentTypeAvailabilityId: guid; removeAppointmentType: boolean }
  >(
    ({ appointmentTypeAvailabilityId, removeAppointmentType }) =>
      providerApi.deleteAppointmentTypeAvailability(
        appointmentTypeAvailabilityId,
        removeAppointmentType
      ),
    {
      onSuccess: (success, request) => {
        updateProviderandProviderList(locationId, providerId, queryClient);
        queryClient.invalidateQueries(
          ProviderCacheKeys.AppointmentTypeAvailability
        );
        if (success) {
          cacheUpdateItemArray(
            queryClient,
            [
              ProviderCacheKeys.AppointmentTypeAvailability,
              locationId,
              providerId,
              appointmentTypeId
            ],
            cached =>
              cached.filter(
                prev => prev.id !== request.appointmentTypeAvailabilityId
              )
          );
        }
      }
    }
  );
};

export const useUpdateAppointmentTypeAvailabilityMutation = () => {
  const queryClient = useQueryClient();
  const { providerApi } = useGateways();

  return useMutation<
    AppointmentTypeAvailabilityDto,
    unknown,
    { availability: AppointmentTypeAvailabilityDto }
  >(
    ({ availability }) =>
      providerApi.updateAppointmentTypeAvailability(availability),
    {
      onSuccess: (availability: AppointmentTypeAvailabilityDto) => {
        cacheUpsertSingleItemInArray({
          queryClient,
          queryKey: [
            ProviderCacheKeys.AppointmentTypeAvailability,
            availability.locationId,
            availability.providerId,
            availability.appointmentTypeId
          ],
          item: availability
        });
      }
    }
  );
};
