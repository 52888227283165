import { guid } from "core/types/Guid";
import {
  UnavailabilityDto,
  UnavailabilityUpdateDto
} from "modules/booking/models";

import { AxiosInstance } from "@bps/http-client";
import { DateTime } from "@bps/utils";

type UnavailabilityResponseDto = Omit<
  UnavailabilityDto,
  "startDate" | "endDate"
> & {
  startDate: string;
  endDate: string | null;
};

const mapStringsToDates = (
  data: UnavailabilityResponseDto
): UnavailabilityDto => {
  const { startDate, endDate, ...rest } = data;
  return {
    ...rest,
    startDate: new Date(startDate),
    endDate: endDate ? new Date(endDate) : undefined
  };
};

const mapDatesToStrings = (data: UnavailabilityUpdateDto) => {
  const { startDate, endDate, ...rest } = data;
  return {
    startDate: DateTime.fromJSDate(startDate).toISODate(),
    endDate: endDate ? DateTime.fromJSDate(endDate).toISODate() : null,
    ...rest
  };
};

export class UnavailabilityGateway {
  constructor(private api: AxiosInstance) {}

  public getExclusionPeriodsForLocation = async (
    locationId: guid
  ): Promise<UnavailabilityDto[]> => {
    const { data } = await this.api.get<UnavailabilityResponseDto[]>(
      `unavailability?locationId=${locationId}`
    );

    return data.map(mapStringsToDates);
  };

  public addExclusionPeriod = async (
    exclusionPeriod: UnavailabilityUpdateDto
  ): Promise<UnavailabilityDto> => {
    const { data } = await this.api.post<UnavailabilityResponseDto>(
      "unavailability",
      mapDatesToStrings(exclusionPeriod)
    );
    return mapStringsToDates(data);
  };

  public updateExclusionPeriod = async (
    exclusionPeriod: UnavailabilityUpdateDto
  ): Promise<UnavailabilityDto> => {
    const { data } = await this.api.put<UnavailabilityResponseDto>(
      `unavailability/${exclusionPeriod.id}`,
      mapDatesToStrings(exclusionPeriod),
      {
        headers: {
          eTag: exclusionPeriod.eTag
        }
      }
    );

    return mapStringsToDates(data);
  };

  public deleteExclusionPeriod = async (id: string): Promise<boolean> => {
    const { data } = await this.api.delete<boolean>(`unavailability/${id}`);
    return data;
  };
}
