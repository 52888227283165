export interface UserPreferenceDto {
  name: string;
  value: string;
}

export class UserPreferenceModel {
  public settingName: string;
  public settingValue: string;

  constructor(public values: UserPreferenceDto) {
    this.settingName = values.name;
    this.settingValue = values.value;
  }
}

export interface UserPreferenceArgs {
  names: string[];
}
