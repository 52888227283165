import { config } from "core/config";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

export const useAppInsightsPageViewTracker = () => {
  const appInsightPlugin = useAppInsightsContext();
  const location = useLocation();
  useEffect(() => {
    if (config.environment && import.meta.env.MODE === "development") {
      appInsightPlugin.trackPageView &&
        appInsightPlugin.trackPageView({
          name: document.title,
          uri: location.pathname
        });
    }
  }, [appInsightPlugin, location]);
};
