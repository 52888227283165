import { NavigateToLink } from "modules/booking/components/NavigateToLink";
import { WarningFontIcon } from "modules/booking/components/WarningFontIcon";
import { FunctionComponent } from "react";

import { MessageBar, MessageBarType, Text } from "@bps/fluent-ui";

interface MultiApptWarningMessageBarProps {}
export const MultiApptWarningMessageBar: FunctionComponent<
  MultiApptWarningMessageBarProps
> = () => {
  return (
    <MessageBar
      messageBarType={MessageBarType.severeWarning}
      styles={{
        iconContainer: { display: "none" },
        innerText: { whiteSpace: "none" },
        root: { minHeight: "auto" }
      }}
    >
      <Text variant="small">
        Your appointment type has been set up to allow multiple bookings per
        appointment slot in Bp Premier. Please review and update impacted
        'custom' durations in{" "}
        <NavigateToLink
          text="Appointment Types"
          navigateTo="/booking/appointment-types"
        />{" "}
        and <NavigateToLink text="Providers" navigateTo="/booking/providers" />.
        Records requiring action are denoted with the following icon
      </Text>
      <WarningFontIcon
        iconStyles={{ root: { marginLeft: 4, paddingBottom: 2 } }}
      />
    </MessageBar>
  );
};
