import { AuthorizationErrorBox } from "common/ui-components";
import {
  PermissionsOperator,
  usePermissionsChecker
} from "core/auth/Permissions";
import { PropsWithChildren } from "react";

interface Props {
  permissions?: string | string[];
  operator?: PermissionsOperator;
}

export const Authorized = ({
  permissions,
  operator,
  children
}: PropsWithChildren<Props>) => {
  const hasPermissions = usePermissionsChecker();

  if (!hasPermissions(permissions, operator)) {
    return <AuthorizationErrorBox />;
  }

  return <>{children}</>;
};
