export class ValidationError extends Error {
  errors: ErrorsMap[] = [];
  constructor(
    message?: string | undefined,
    errors?: ErrorsMap[],
    public traceId?: string
  ) {
    super(message ? message : toString(errors));

    // https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, ValidationError.prototype);

    if (errors) {
      this.errors = errors;
    }
  }
}

export interface ErrorsMap {
  ErrorMessage: string;
  MemberNames: string[];
}
function toString(errors?: ErrorsMap[]) {
  if (errors!.length < 1 || errors === undefined) {
    return "A validation error occurred";
  }

  const messages = errors.map(error => error.ErrorMessage);
  return messages.join(", ");
}
