import {
  ConcurrencyError,
  ConflictError,
  DuplicateError,
  NetworkError
} from "common/apiErrors";
import {
  ErrorsMap,
  PrOSErrorsMap,
  PrOSValidationError,
  ValidationError
} from "common/Validation";
import { ErrorType } from "core/middleware/exception-filter.enums";
import { ERROR_TYPE, TRACE_ID } from "core/types/api.constants";

import {
  AuthorisationError,
  AxiosError,
  IMiddleware,
  NotFoundError,
  ServerError
} from "@bps/http-client";

export class ExceptionFilter implements IMiddleware<AxiosError> {
  constructor(private traceId?: string) {}
  public onRejected = (error: AxiosError<any>): Promise<AxiosError> => {
    if (error.response) {
      // Last TraceID used by AppInsights. It should correspond to the AJAX Request operation id.
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      const errorType: ErrorType | undefined =
        error.response.headers && error.response.headers[ERROR_TYPE];

      if (error.response.status >= 500) {
        return Promise.reject(
          new ServerError(error.response.status, error.response.data, TRACE_ID)
        );
      } else if (error.response.status === 404) {
        return Promise.reject(new NotFoundError(error.response.data, TRACE_ID));
      } else if (error.response.status === 412) {
        return Promise.reject(new ConflictError(error.response.data, TRACE_ID));
      } else if (error.response.status === 409) {
        return Promise.reject(
          new ConcurrencyError(error.response.data, TRACE_ID)
        );
      } else {
        // all other 4xx errors
        let err: Error;
        // Beef Validation Error
        if (errorType === ErrorType.ValidationError) {
          // ValidationError is supposed to be an object where keys are the field name and values
          // are the messages. Sometimes the API returns a single string as the data
          if (typeof error.response.data === "string") {
            err = new ValidationError(error.response.data, undefined, TRACE_ID);
          } else {
            err = new ValidationError(
              undefined,
              error.response.data as ErrorsMap[],
              TRACE_ID
            );
          }
          // Beef Business Error
        } else if (
          errorType === ErrorType.BusinessError &&
          error.response.data
        ) {
          err = new ValidationError(
            error.response.data as string,
            undefined,
            TRACE_ID
          );
          // Beef Authorization Error
        } else if (errorType === ErrorType.AuthorizationError) {
          err = new AuthorisationError(
            error.response.status,
            error.response.data as string,
            TRACE_ID
          );
          // Beef Duplicate Error
        } else if (errorType === ErrorType.DuplicateError) {
          err = new DuplicateError(error.response.data as string, TRACE_ID);

          // Non-Beef Validation Error
        } else {
          err = new PrOSValidationError(
            error.response.statusText,
            error.response.data?.errors as PrOSErrorsMap[],
            TRACE_ID
          );
        }
        return Promise.reject(err);
      }
    } else if (error.request) {
      // The request was made but no response was received or the response could not be read (missing CORS header)
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      return Promise.reject(new NetworkError(error.message));
    } else {
      // Something happened in setting up the request that triggered an Error
      return Promise.reject(
        new Error(typeof error === "string" ? error : error.message)
      );
    }
  };
}
