import { config } from "core/config";
import { GatewaysContext } from "core/gateways-context";
import {
  LocationGateway,
  ProviderGateway,
  UnavailabilityGateway
} from "core/gateways/practice";
import { LicenceGateway, TenantGateway } from "core/gateways/tenant";
import { UserGateway } from "core/gateways/user/UserGateway";
import { ExceptionFilter } from "core/middleware";
import { TenantInterceptorMiddleware } from "core/middleware/tenant-interceptor.middleware";
import { NO_AUTH } from "core/types/api.constants";
import { PrOsApiSchema } from "core/types/api.interface";
import { PropsWithChildren } from "react";
import { QueryClient, QueryClientProvider, UseQueryOptions } from "react-query";

import {
  AuthorizationMiddleware,
  AxiosHelperBase,
  EndpointConfig
} from "@bps/http-client";

export type QueryOptions<TQueryFnData, TData> = Omit<
  UseQueryOptions<TQueryFnData, unknown, TData>,
  "queryKey" | "queryFn"
>;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: config.query.staleTimeMillis
    }
  }
});

export const ReactQueryProvider = ({
  children
}: PropsWithChildren<unknown>) => {
  const camEndpoint: EndpointConfig<PrOsApiSchema> = {
    practice: { baseURL: config.apiUrl },
    tenant: { baseURL: config.tgApiUrl }
  };

  const axiosHelper = new AxiosHelperBase<PrOsApiSchema>(camEndpoint, {
    request: [
      new AuthorizationMiddleware(NO_AUTH),
      new TenantInterceptorMiddleware()
    ],
    respond: [new ExceptionFilter()]
  });

  const api = axiosHelper.initialize();

  const locationApi = new LocationGateway(api.practice);
  const providerApi = new ProviderGateway(api.practice);
  const unavailabilityApi = new UnavailabilityGateway(api.practice);

  const userApi = new UserGateway(api.tenant);
  const licenceApi = new LicenceGateway(api.tenant);
  const tenantApi = new TenantGateway(api.tenant);

  return (
    <GatewaysContext.Provider
      value={{
        locationApi,
        providerApi,
        unavailabilityApi,
        userApi,
        licenceApi,
        tenantApi
      }}
    >
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </GatewaysContext.Provider>
  );
};
