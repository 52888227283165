import { useGateways } from "core/gateways-context";
import { UserDto } from "core/models";
import {
  UserPreferenceArgs,
  UserPreferenceModel
} from "core/models/userPreference.model";
import { useQuery, UseQueryResult } from "react-query";

import { useAccount } from "@azure/msal-react";

export enum UserCacheKeys {
  Profile = "user-profile",
  Preferences = "user-preferences"
}

export const useCurrentUserQuery = (): UseQueryResult<UserDto> => {
  const account = useAccount();
  const { userApi } = useGateways();

  return useQuery<UserDto>(UserCacheKeys.Profile, userApi.getCurrentUser, {
    cacheTime: Infinity,
    staleTime: Infinity,
    enabled: !!account
  });
};

export const useUserPreferencesQuery = () => {
  const { userApi } = useGateways();

  const args: UserPreferenceArgs = {
    names: ["AppointmentLength"]
  };
  return useQuery<UserPreferenceModel[], unknown>(
    UserCacheKeys.Preferences,
    async () => await userApi.getUserPreferences(args)
  );
};
