import { useCurrentUserQuery } from "core/gateways/user/UserHooks";
import { useCallback } from "react";

export type PermissionsSpec = string | string[];
export type PermissionsOperator = "and" | "or";

type PermissionsChecker = (
  permission?: PermissionsSpec,
  operator?: PermissionsOperator
) => boolean;

export const usePermissionsChecker = (): PermissionsChecker => {
  const { data: user } = useCurrentUserQuery();
  const userPermissions = user?.permissions;

  return useCallback(
    (permission: string[] | string, operator: PermissionsOperator = "and") => {
      if (!permission) return true;
      if (Array.isArray(permission) && !permission.length) return true;

      if (!userPermissions?.length) return false;

      const hasPermission = (permission: string) =>
        userPermissions.includes(permission);

      if (typeof permission === "string") return hasPermission(permission);

      return operator === "or"
        ? permission.some(hasPermission)
        : permission.every(hasPermission);
    },
    [userPermissions]
  );
};
