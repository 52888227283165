import { FontIcon, IFontIconStyles, ITheme, useTheme } from "@bps/fluent-ui";

interface WarningFontIconProps {
  iconStyles?: IFontIconStyles;
}

export const getWarningFontIconStyles = (
  theme: ITheme,
  iconStyles?: IFontIconStyles
) => ({
  root: {
    fontSize: 16,
    color: theme.semanticColors.severeWarningIcon,
    alignSelf: "center",
    transform: "rotate(-180deg)",
    ...iconStyles?.root
  }
});

export const WarningFontIcon = ({ iconStyles }: WarningFontIconProps) => {
  const theme = useTheme();

  return (
    <FontIcon
      iconName="Info"
      styles={getWarningFontIconStyles(theme, iconStyles)}
    />
  );
};
