import { ErrorAlert } from "common/ui-components/Alert";
import { ReactNode } from "react";

import { Spinner, SpinnerSize, Stack } from "@bps/fluent-ui";

export interface QueryStateIndicatorProps<TData> {
  isLoading: boolean;
  loadingTitle?: string;
  error?: Error | Error[] | unknown;
  data: TData;
  children: ReactNode | ((data: TData) => ReactNode);
}

export const QueryStateIndicator = <TData extends unknown>({
  isLoading,
  loadingTitle,
  error,
  data,
  children
}: QueryStateIndicatorProps<TData>) => {
  if (isLoading) {
    return (
      <Stack verticalFill={true} verticalAlign="center">
        <Spinner
          label={loadingTitle || "Loading, please wait..."}
          ariaLive="assertive"
          labelPosition="top"
          size={SpinnerSize.large}
        />
      </Stack>
    );
  }

  const errorMessage = getErrorMessage(error, data);
  if (errorMessage) {
    return <ErrorAlert error={errorMessage} />;
  }

  if (typeof children === "function") {
    return <>{children(data)}</>;
  }

  return <>{children}</>;
};

function getErrorMessage<TData>(
  error: Error | Error[] | unknown | undefined,
  data: TData
) {
  if (!error && data) return undefined;

  if (Array.isArray(error)) {
    return error.length ? error[0] : undefined;
  }

  return (error as Object)?.toString() ?? "The data could not be loaded.";
}
