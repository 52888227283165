export class PrOSValidationError extends Error {
  errors: PrOSErrorsMap[] = [];
  constructor(
    message?: string | undefined,
    errors?: PrOSErrorsMap[],
    public traceId?: string
  ) {
    super(message ? message : toString(errors));

    // https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, PrOSValidationError.prototype);

    if (errors) {
      this.errors = errors;
    }
  }
}

export interface PrOSErrorsMap {
  [fieldName: string]: string[];
}

function toString(errors?: PrOSErrorsMap[]) {
  if (errors!.length < 1 || errors === undefined) {
    return "A validation error occurred";
  }

  const messages = Object.keys(errors).map(ErrorName => errors[ErrorName]);
  return messages.join(", ");
}
