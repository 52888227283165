import { guid } from "core/types/Guid";
import { action, makeObservable, observable } from "mobx";

export class CoreStore {
  locationId: guid;

  public setCurrentLocation = (locationId: string) => {
    this.locationId = locationId;
  };

  constructor() {
    makeObservable(this, {
      locationId: observable,
      currentUserId: observable,
      setCurrentLocation: action,
      setCurrentUserId: action
    });
  }
  currentUserId: guid;

  public setCurrentUserId = (userId: string) => {
    this.currentUserId = userId;
  };
}
