import { FieldsAutoSaveWrapper } from "common/ui-components/form/FieldsAutoSaveWrapper";
import { config } from "core/config";
import { Environment } from "core/Environment";
import { isEqual } from "lodash";
import { useEffect, useRef } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FieldValues } from "react-hook-form/dist/types/fields";

import { classNamesFunction, styled, useTheme } from "@bps/fluent-ui";
import { DevTool } from "@hookform/devtools";

import { getFormStyles } from "./Form.styles";
import { FormProps, FormStyles, FormStylesProps } from "./Form.types";
import { FormSubmitCancelButtons } from "./FormSubmitCancelButtons";
import { validationResolver } from "./validation/validation-resolver";

const getFormClassNames = classNamesFunction<FormStylesProps, FormStyles>();

const FormBase = <FormValues extends FieldValues = FieldValues>(
  props: FormProps<FormValues>
) => {
  const {
    defaultValues,
    validationMode = "onChange",
    reValidateMode = "onChange",
    children,
    onSubmit,
    styles,
    id,
    onRenderSubmitCancelButtons,
    submitCancelButtonsProps,
    form: externalForm,
    autoSave,
    autoSaveDelay = 500,
    validate
  } = props;

  const defaultValuesRef = useRef(defaultValues);
  const theme = useTheme();
  const classNames = getFormClassNames(styles, { theme });
  const currentForm = useForm<FormValues>({
    mode: validationMode,
    reValidateMode,
    defaultValues,
    resolver: validate
      ? (values: FormValues) => validationResolver(validate, values)
      : undefined
  });

  const form = externalForm ?? currentForm;

  // reset form in a case of new defaultValues
  useEffect(() => {
    if (!isEqual(defaultValuesRef.current, defaultValues)) {
      defaultValuesRef.current = defaultValues;
      // Note it is a partial reset and there is bug when you reinitialize form and a new value is undefined it does not
      // merge old adn new values property. In a new major react-form-hooks update it will be fixed.
      // https://github.com/react-hook-form/react-hook-form/discussions/8138
      form.reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <>
      <FormProvider<FormValues> {...form}>
        <form
          id={id}
          autoComplete="off"
          className={classNames.root}
          onSubmit={form.handleSubmit(async values => {
            try {
              await onSubmit(values, form);
            } catch (e) {
              // Can be accessed via formState.errors.root["Server"] to display error
              form.setError("root.server", {
                message: e.message,
                type: "server"
              });
            }
          })}
        >
          <FieldsAutoSaveWrapper
            className={classNames.fields}
            onSubmit={onSubmit}
            autoSave={autoSave}
            autoSaveDelay={autoSaveDelay}
          >
            {children}
          </FieldsAutoSaveWrapper>
          {!autoSave && (
            <FormSubmitCancelButtons<FormValues>
              {...submitCancelButtonsProps}
              className={classNames.buttons}
              onRenderSubmitCancelButtons={onRenderSubmitCancelButtons}
            />
          )}
        </form>
      </FormProvider>

      {/* ReactFormHooks Dev Tools https://react-hook-form.com/dev-tools */}
      {config.environment === Environment.Dev &&
        process.env.NODE_ENV === "development" && (
          <div id="react-hooks-dev-tools">
            <DevTool placement="bottom-right" control={form.control} />
          </div>
        )}
    </>
  );
};

const FormUnTyped = styled<FormProps, FormStylesProps, FormStyles>(
  FormBase,
  getFormStyles
);

export const Form = <FormValues extends FieldValues = FieldValues>(
  props: FormProps<FormValues>
) => <FormUnTyped {...(props as FormProps)} />;
