import { FormStyles } from "./Form.types";

export const getFormStyles = (): FormStyles => ({
  root: { display: "flex", flexDirection: "column", height: "100%" },
  fields: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    overflowY: "auto"
  },
  buttons: {
    display: "flex",
    padding: 16,
    width: "100%",
    justifyContent: "flex-end"
  }
});
