import { useCoreStore } from "common/StoresProvider";
import { useLocationsQuery } from "core/gateways/practice/LocationHooks";
import { navBarActionButtonStyles } from "layout/NavBar";
import { observer } from "mobx-react-lite";
import { useLocation, useNavigate } from "react-router";

import { ActionButton, IContextualMenuItem, useTheme } from "@bps/fluent-ui";

export const LocationPicker = observer(() => {
  const core = useCoreStore();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const { isLoading, data: locations } = useLocationsQuery();
  const currentLocation = locations?.find(l => l.id === core.locationId);

  if (isLoading || !locations?.length) return null;

  const handleLocationClicked = (_, data: IContextualMenuItem) => {
    if (location.pathname.match("booking/providers/")) {
      navigate("./booking/providers");
    }

    core.setCurrentLocation(data.key);
  };

  const locationItems = locations.map(
    (location): IContextualMenuItem => ({
      id: `location-${location.id}`,
      key: location.id,
      text: location.name,
      onClick: handleLocationClicked
    })
  );

  return (
    <ActionButton
      text={currentLocation?.name ?? "Please select location"}
      menuProps={{
        shouldFocusOnMount: true,
        items: locationItems
      }}
      styles={navBarActionButtonStyles(theme)}
    />
  );
});
