import { AxiosResponse } from "axios";
import { RefDataDto } from "common/dtos";
import { ProviderLocationsDto } from "core/models/licence";
import { CONTENT_TYPE_HEADER } from "core/types/api.constants";
import { guid } from "core/types/Guid";
import {
  ProviderAppointmentTypeDto,
  ProviderDto
} from "modules/booking/models";
import { AppointmentTypeAvailabilityDto } from "modules/booking/models/appointmentTypeAvailability.model";
import {
  PatientConfigRequestDto,
  PatientConfigResponseDto
} from "modules/booking/models/patientConfig.model";

import { AxiosInstance } from "@bps/http-client";
import { DateTime } from "@bps/utils";

export interface LocationProviderDto extends ProviderDto {
  locationId: guid;
}

export type AppointmentTypeAvailabilityResponseDto = Omit<
  AppointmentTypeAvailabilityDto,
  "startDate" | "endDate"
> & {
  startDate: string;
  endDate: string | null;
};

const mapStringsToDates = (
  data: AppointmentTypeAvailabilityResponseDto
): AppointmentTypeAvailabilityDto => {
  const { startDate, endDate, ...rest } = data;
  return {
    ...rest,
    startDate: new Date(startDate),
    endDate: endDate ? new Date(endDate) : null
  };
};

const mapDatesToStrings = (
  data: AppointmentTypeAvailabilityDto
): AppointmentTypeAvailabilityResponseDto => {
  const { startDate, endDate, ...rest } = data;
  return {
    startDate: DateTime.fromJSDate(startDate).toISODate(),
    endDate: endDate ? DateTime.fromJSDate(endDate).toISODate() : null,
    ...rest
  };
};

export enum ProviderRefDataKeys {
  language = "language",
  genderIdentity = "genderIdentity",
  areaOfInterest = "areaOfInterest",
  providerOnlineStatus = "providerOnlineStatus"
}

export class ProviderGateway {
  constructor(private api: AxiosInstance) {}

  public getProvidersForLocation = async (locationId: guid) => {
    const { data } = await this.api.get<ProviderDto[]>(
      `location/${locationId}/providers`
    );
    return data;
  };

  public getProviderLocations = async () => {
    const { data } = await this.api.get<ProviderLocationsDto[]>(
      "provider/licence/providerLocations"
    );
    return data;
  };

  public getPatientConfig = async (
    providerId: guid,
    locationId: guid,
    appointmentTypeId: guid
  ) => {
    const { data } = await this.api.get<PatientConfigResponseDto>(
      `provider/${providerId}/location/${locationId}/appointmentType/${appointmentTypeId}/patientConfiguration`
    );
    return data;
  };

  public updatePatientConfig = async (payload: {
    providerId: guid;
    locationId: guid;
    appointmentTypeId: guid;
    request: PatientConfigRequestDto;
  }) => {
    const { providerId, locationId, appointmentTypeId, request } = payload;
    const { data } = await this.api.put<PatientConfigResponseDto>(
      `provider/${providerId}/location/${locationId}/appointmentType/${appointmentTypeId}/patientConfiguration`,
      request
    );
    return data;
  };

  public getAppointmentTypeAvailabilities = async (
    providerId: guid,
    locationId: guid,
    appointmentTypeId: guid
  ) => {
    const { data } = await this.api.get<
      AppointmentTypeAvailabilityResponseDto[]
    >(
      `provider/${providerId}/location/${locationId}/availabilities/${appointmentTypeId}`
    );

    return data.map(mapStringsToDates);
  };

  public createAppointmentTypeAvailability = async (
    availability: AppointmentTypeAvailabilityDto
  ): Promise<AppointmentTypeAvailabilityDto> => {
    const { data } = await this.api.post<
      AppointmentTypeAvailabilityDto,
      AxiosResponse<AppointmentTypeAvailabilityResponseDto>
    >(
      "provider/appointmentType/availabilityRule",
      mapDatesToStrings(availability)
    );

    return mapStringsToDates(data);
  };

  public updateAppointmentTypeAvailability = async (
    availability: AppointmentTypeAvailabilityDto
  ): Promise<AppointmentTypeAvailabilityDto> => {
    const { data } = await this.api.put<
      AppointmentTypeAvailabilityDto,
      AxiosResponse<AppointmentTypeAvailabilityResponseDto>
    >(
      "provider/appointmentType/availabilityRule",
      mapDatesToStrings(availability),
      {
        headers: {
          eTag: availability.eTag
        }
      }
    );

    return mapStringsToDates(data);
  };

  public deleteAppointmentTypeAvailability = async (
    appointmentTypeAvailabilityId: guid,
    removeAppointmentType: boolean
  ): Promise<boolean> => {
    const { data } = await this.api.delete<guid, AxiosResponse<boolean>>(
      `provider/appointmentType/availabilityRule/${appointmentTypeAvailabilityId}`,
      { params: { removeAppointmentType } }
    );

    return data;
  };

  public updateProviderForLocation = async (
    provider: LocationProviderDto
  ): Promise<ProviderDto> => {
    const { data } = await this.api.put<
      LocationProviderDto,
      AxiosResponse<ProviderDto>
    >(`provider/${provider.id}`, provider, {
      headers: {
        eTag: provider.eTag
      }
    });

    return data;
  };

  public getAppointmentTypesForProvider = async (
    providerId: guid,
    locationId: guid
  ) => {
    const { data } = await this.api.get<ProviderAppointmentTypeDto[]>(
      `provider/${providerId}/location/${locationId}/appointmentTypes`
    );
    return data;
  };

  public uploadProviderPhoto = async (
    locationId: string,
    providerId: string,
    file: File
  ): Promise<string> => {
    const formData = new FormData();
    formData.append("fileData", file);

    const { data } = await this.api.post(
      `provider/${providerId}/location/${locationId}/uploadPhoto`,
      formData,
      {
        headers: {
          [CONTENT_TYPE_HEADER]: "multipart/form-data"
        }
      }
    );

    return data.link;
  };

  public getRef = <T extends RefDataDto = RefDataDto>(name: string) =>
    this.api.get<T[]>(`/ref/${name}`).then(x => x.data);
}
