import { useGateways } from "core/gateways-context";
import { LicenceMappingDto } from "core/models/licenceMapping";
import { OrgUnitDto } from "core/models/orgUnit";
import { TenantProviderDto } from "core/models/tenantProvider";
import { QueryOptions } from "core/ReactQueryProvider";
import {
  AppointmentTypeTGArgs,
  AppointmentTypeTGDto
} from "modules/booking/models";
import { useQuery } from "react-query";

const CacheKeys = {
  OrgUnits: "orgUnits",
  Providers: "providers",
  LicenceMappings: "licenceMappings",
  AppointmentTypesTG: "appointmentTypesTG"
};

export const useOrgUnitsQuery = <TResult>(
  options?: QueryOptions<OrgUnitDto[], TResult>
) => {
  const { tenantApi } = useGateways();
  return useQuery(CacheKeys.OrgUnits, tenantApi.getOrgUnits, options);
};
export const useTenantProvidersQuery = <TResult = TenantProviderDto[]>(
  options?: QueryOptions<TenantProviderDto[], TResult>
) => {
  const { tenantApi } = useGateways();
  return useQuery(CacheKeys.Providers, tenantApi.getTenantProviders, options);
};

export const useLicenceMappingsQuery = <TResult = LicenceMappingDto[]>(
  options?: QueryOptions<LicenceMappingDto[], TResult>
) => {
  const { tenantApi } = useGateways();
  return useQuery(
    CacheKeys.LicenceMappings,
    tenantApi.getLicenceMappings,
    options
  );
};

export const useAppointmentTypesTG = (
  appointmentTypeArgs?: AppointmentTypeTGArgs
) => {
  const { tenantApi } = useGateways();

  // Measure to ensure requests with the same id's but different order aren't considered as a new query
  const sortedIds = [...(appointmentTypeArgs?.appointmentTypeIds ?? [])].sort(
    (a, b) => (a < b ? -1 : 1)
  );

  return useQuery<AppointmentTypeTGDto[], unknown>(
    [CacheKeys.AppointmentTypesTG, sortedIds],
    async () => await tenantApi.getAppointmentTypesTG(appointmentTypeArgs)
  );
};
