import { useNavigate } from "react-router-dom";

import { Link } from "@bps/fluent-ui";

interface Props {
  navigateTo: string;
  text: string;
}
export const NavigateToLink: React.FC<Props> = ({ navigateTo, text }) => {
  const navigate = useNavigate();
  return (
    <Link
      onClick={() => {
        navigate(navigateTo, { replace: true });
      }}
    >
      {text}
    </Link>
  );
};
