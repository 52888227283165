import { useGtmAuthorisation } from "common/hooks/useGtmAuthorisation";
import { Notifications } from "common/Notifications";
import { useStores } from "common/StoresProvider";
import { QueryStateIndicator } from "common/ui-components/QueryStateIndicator";
import { useCurrentUserQuery } from "core/gateways/user/UserHooks";
import { observer } from "mobx-react-lite";
import { BaseIntervalWarning } from "modules/booking/pages/appointmentTypes/warnings/appointments-setting-base-interval-warning";
import { MultiApptWarning } from "modules/booking/pages/appointmentTypes/warnings/appointments-setting-multi-appt-warning";
import { ComponentType, createElement, PropsWithChildren } from "react";

import {
  Customizer,
  IMessageBarProps,
  mergeStyles,
  MessageBarType,
  Stack
} from "@bps/fluent-ui";

import { InfoBarRestriction } from "./InfoBarRestriction";
import { NavBar } from "./NavBar";

export interface MainLayoutProps {
  toolbar?: ComponentType;
}

const messageBarSettings: Partial<IMessageBarProps> = {
  styles: props => ({
    root: props.messageBarType === MessageBarType.info && {
      background: props.theme.palette.white
    }
  })
};

export const MainLayout = observer(
  ({ toolbar, children }: PropsWithChildren<MainLayoutProps>) => {
    const userQuery = useCurrentUserQuery();

    const { core } = useStores();
    const selectedLocationId = core.locationId;
    useGtmAuthorisation(userQuery.data, selectedLocationId);

    const mainStyles = mergeStyles({
      flexGrow: 1,
      backgroundColor: "#f3f3f4",
      overflow: "auto",
      padding: 16,
      position: "relative"
    });

    const toolbarStyles = mergeStyles({
      padding: "0 16px",
      margin: "4px 0"
    });

    return (
      <Stack verticalFill>
        <QueryStateIndicator
          loadingTitle="Loading your profile, please wait..."
          {...userQuery}
        >
          <NavBar />
          {selectedLocationId && (
            <>
              <BaseIntervalWarning locationId={selectedLocationId} />
              <MultiApptWarning locationId={selectedLocationId} />
            </>
          )}
          <InfoBarRestriction />

          {toolbar && (
            <div id="toolbar" className={toolbarStyles}>
              {createElement(toolbar)}
            </div>
          )}
          <Customizer
            scopedSettings={{
              MessageBar: messageBarSettings
            }}
          >
            <Notifications />
          </Customizer>
          <main className={mainStyles}>{children}</main>
        </QueryStateIndicator>
      </Stack>
    );
  }
);
