import { ConflictError, DuplicateError } from "common/apiErrors";
import { ValidationError } from "common/Validation";
import { Component, createRef, MouseEvent, ReactNode } from "react";

import {
  IconButton,
  IMessageBarProps,
  Link,
  mergeStyles,
  MessageBar,
  MessageBarType,
  Stack
} from "@bps/fluent-ui";
import { NotFoundError, ServerError } from "@bps/http-client";

export type ErrorAlertProps = {
  error?: string | Error;
  showReloadLink?: boolean;
} & IMessageBarProps;

export class ErrorAlert extends Component<ErrorAlertProps> {
  private errorDetailsRef = createRef<HTMLTextAreaElement>();

  render() {
    const { error, showReloadLink, ...messageBarProps } = this.props;
    const onClick = (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      window.location.reload();
    };

    const shouldShowReloadLink =
      showReloadLink || error instanceof ConflictError;

    const formatMessage = (error: string | undefined | Error): ReactNode[] => {
      const text = error instanceof Error ? error.message : error;
      return text
        ? text.split("/n").map(item => {
            return (
              <span key={item}>
                {item}
                <br />
              </span>
            );
          })
        : [<span key="StandardErrorMessage">An error occurred</span>];
    };

    let traceId: undefined | string;

    if (
      error instanceof NotFoundError ||
      error instanceof ConflictError ||
      error instanceof ValidationError ||
      error instanceof DuplicateError
    ) {
      traceId = error.traceId;
    }

    if (error instanceof ServerError) {
      traceId = error.traceId;
    }

    let errorDetails = error instanceof Error ? error.message : error;
    if (traceId) {
      errorDetails += `Trace ID: ${traceId}`;
    }
    if (error instanceof ServerError && error.detail) {
      errorDetails += `Data: ${error.detail}`;
    }

    return (
      <MessageBar messageBarType={MessageBarType.error} {...messageBarProps}>
        <Stack verticalAlign="center" horizontal tokens={{ childrenGap: 4 }}>
          <Stack.Item>{formatMessage(error)} </Stack.Item>
          <IconButton
            title="Copy error details to clipboard"
            ariaLabel="Copy error details to clipboard"
            iconProps={{ iconName: "Copy" }}
            onClick={() => {
              this.errorDetailsRef.current?.select();
              document.execCommand("copy");
            }}
          />
        </Stack>

        <br />
        {shouldShowReloadLink && (
          <span>
            <Link href="#" onClick={onClick}>
              Click here
            </Link>{" "}
            to reload the page
          </span>
        )}
        <textarea
          readOnly
          ref={this.errorDetailsRef}
          className={mergeStyles({
            height: 0,
            position: "absolute",
            zIndex: -1
          })}
          value={errorDetails}
        />
      </MessageBar>
    );
  }
}
