import { useEffect } from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

export const useGoogleAnalyticsPageTracking = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    ReactGA.set({ page: pathname });
    ReactGA.pageview(pathname);
  }, [pathname]);
};
