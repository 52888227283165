import { ReactNode } from "react";
import { LinkProps, useMatch } from "react-router-dom";

export type NavItemRenderProps = Pick<LinkProps, "to"> & {
  /**
   * True when "to" matches the current location
   */
  isActive: boolean;
};

export type NavItemProps = Pick<LinkProps, "to"> & {
  children?: (props: NavItemRenderProps) => ReactNode;
};

/**
 * This component can be used when a component needs to know whether a path matches the current location,
 * to, for example, style the current navigation link differently.
 *
 * It expects a render function as a child, passing in whether a given path matches the current location
 *
 * Example:
 * <NavItem to={routes.calendarEvents.basePath}>
 *                {({ isActive, to }) => (
 *                   <li>
 *                    {isActive ? "Current location matches Calendar path" : <Link to={to}>Go to Calendar page</Link>}
 *                   </li>
 *                 )}
 *  </NavItem>
 */
export const NavItem = ({ to, children }: NavItemProps) => {
  const match = useMatch(`${to}/*`);

  if (!children) return null;

  return <>{children({ to, isActive: !!match })}</>;
};
