import { InternalAxiosRequestConfig } from "axios";
import { X_TENANT } from "core/types/api.constants";

import { getAccessToken, IMiddleware } from "@bps/http-client";

/**
 * Returns a request interceptor that will add an X-Tenant HTTP header
 * with either the value of tenant if provided or will default to the
 * value of the hostname
 */
export class TenantInterceptorMiddleware
  implements IMiddleware<InternalAxiosRequestConfig>
{
  onFulfilled = async (
    config: InternalAxiosRequestConfig
  ): Promise<InternalAxiosRequestConfig> => {
    const token = (await getAccessToken(true)) as string | undefined;
    if (!token) return config;

    const decoded = JSON.parse(atob(token.split(".")[1]));
    const tenant = decoded.name.split("\\")[0];
    if (tenant) {
      config.headers[X_TENANT] = tenant;
    }

    return config;
  };
}
