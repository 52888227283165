import { useUserPreferencesStore } from "common/StoresProvider";
import { observer } from "mobx-react-lite";
import { FunctionComponent, PropsWithChildren } from "react";

/**
 * The component renders children when the config.development is set to true or when the
 * feature has been toggled through the menu option
 * @param children
 * @constructor
 */

interface WhenFeatureToggledProps {
  else?: JSX.Element;
}
export const WhenFeatureToggled: FunctionComponent<
  PropsWithChildren<WhenFeatureToggledProps>
> = observer(props => {
  const { areToggledFeaturesVisible } = useUserPreferencesStore();

  return areToggledFeaturesVisible ? <>{props.children}</> : props.else ?? null;
});
