import { WarningFontIcon } from "modules/booking/components/WarningFontIcon";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";

import { Link, MessageBar, MessageBarType, Text } from "@bps/fluent-ui";

interface BaseIntervalWarningMessageBarProps {}
export const BaseIntervalWarningMessageBar: FunctionComponent<
  BaseIntervalWarningMessageBarProps
> = () => {
  const navigate = useNavigate();

  const defaultLink = (
    <Link
      href="https://kb.bpsoftware.net/bppremier/Orchid/Management/AppointmentBook/AdjustDefaultApptTime.htm?"
      target="_blank"
    >
      default appointment length
    </Link>
  );

  const appointmentTypesLink = (
    <Link
      onClick={() => {
        navigate("/booking/appointment-types", { replace: true });
      }}
    >
      Appointment Types
    </Link>
  );

  const providersLink = (
    <Link
      onClick={() => {
        navigate("/booking/providers", { replace: true });
      }}
    >
      Providers
    </Link>
  );

  return (
    <MessageBar
      messageBarType={MessageBarType.severeWarning}
      styles={{
        iconContainer: { display: "none" },
        innerText: {
          whiteSpace: "none"
        },
        root: { minHeight: "auto" }
      }}
    >
      <Text variant="small">
        Your Bp Premier {defaultLink} has changed. Please review and update
        impacted {appointmentTypesLink} and {providersLink} that have customised
        appointments. Records requiring action are denoted with the icon
      </Text>
      <WarningFontIcon
        iconStyles={{ root: { marginLeft: 4, paddingBottom: 2 } }}
      />
    </MessageBar>
  );
};
