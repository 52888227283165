import {
  checkLocationBaseInterval,
  checkProviderBaseInterval
} from "common/utils";
import { useLocationAppointmentTypesQuery } from "core/gateways/practice/LocationHooks";
import { useLocationProvidersQuery } from "core/gateways/practice/ProviderHooks";
import { useUserPreferencesQuery } from "core/gateways/user/UserHooks";
import { observer } from "mobx-react-lite";

import { BaseIntervalWarningMessageBar } from "./appointment-types-base-interval-warning-message-bar";

interface BaseIntervalWarningProps {
  locationId: string;
}

export const BaseIntervalWarning = observer(
  ({ locationId }: BaseIntervalWarningProps) => {
    const providersQuery = useLocationProvidersQuery(locationId);
    const appointmentTypesQuery = useLocationAppointmentTypesQuery(locationId);
    const userPreferencesQuery = useUserPreferencesQuery();

    if (
      !providersQuery.isSuccess ||
      !appointmentTypesQuery.isSuccess ||
      !userPreferencesQuery.isSuccess
    ) {
      return null;
    }

    const providers = providersQuery.data;
    const appointmentTypes = appointmentTypesQuery.data;
    const userPreferences = userPreferencesQuery.data;

    const baseInterval = Number(
      userPreferences?.find(
        up => up.settingName.toLowerCase() === "appointmentlength"
      )?.settingValue
    );

    if (!(providers?.length && appointmentTypes?.length && baseInterval > 0)) {
      return null;
    }

    // Check if any appointment type duration is not a interval of the base appointment type duration.
    // If true, we will display a alert banner across PrOS

    // Check Location Customised appointment types
    const isLocationBaseInterval =
      appointmentTypes.filter(at => checkLocationBaseInterval(at, baseInterval))
        .length > 0;

    // Check Provider Customised appointment types
    const isProviderBaseInterval =
      providers.filter(
        p =>
          p.appointmentTypes.filter(pat =>
            checkProviderBaseInterval(pat, baseInterval)
          ).length > 0
      ).length > 0;

    return (
      <>
        {(isLocationBaseInterval || isProviderBaseInterval) && (
          <BaseIntervalWarningMessageBar />
        )}
      </>
    );
  }
);
