import { Environment } from "core/Environment";

import Hotjar from "@hotjar/browser";

import { config } from "../../config";

const HOTJAR_VERSION = 6;

export const initHotJar = () => {
  if (
    window.appConfig.environment === Environment.Dev ||
    window.appConfig.environment === Environment.Uat ||
    window.appConfig.environment === Environment.Prod
  ) {
    const siteId = Number(config.PrOSHotJarSiteId);
    Hotjar.init(siteId, HOTJAR_VERSION);
  }
};
