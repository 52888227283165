export interface AppointmentTypeDto {
  id: string;
  eTag: string;
  name: string;
  duration?: string;
  onlineName?: string;
  onlineDescription?: string;
  minuteDuration?: number;
  sortOrder?: number;
  emailContent?: string;
  emailContentEnabled?: boolean;
  isAvailableExistingPatients?: boolean;
  isAvailableNewPatients?: boolean;
  newPatientDuration?: number;
  isCancellable?: boolean;
  customised?: boolean;
  allowPatientNotes?: boolean;
  appointmentInformationEnabled?: boolean;
  appointmentInformation?: string;
  displayAppointmentLength?: boolean;
  callClinicEnabled?: boolean;
}

export interface AppointmentTypeSortOrderDto {
  id: string;
  sortOrder?: number;
}

export interface AppointmentTypeTGArgs {
  appointmentTypeIds: string[];
}

export interface AppointmentTypeTGDto {
  id: string;
  name: string;
  duration?: number;
  groupBooking?: boolean;
  maxGroupSize?: number;
  premId: number;
}

export interface AppointmentTypeCombined extends AppointmentTypeDto {
  groupBooking?: boolean;
  maxGroupSize?: number;
  durationTG?: number;
  bookingStatus?: BookingStatus;
  premId?: number;
}

export enum BookingStatus {
  Online = "1",
  Offline = "0",
  CallClinic = "2"
}
