import { RefDataDto } from "common/dtos";

export type KeyText = {
  key: string;
  text: string;
};

export type KeyName = Omit<KeyText, "text"> & {
  name: string;
};

export class RefDataModel {
  constructor(public values: RefDataDto[]) {}

  get keyTextValue() {
    return this.values.map<KeyText>(v => ({ key: v.code, text: v.text }));
  }

  get keyNameValue() {
    return this.values.map<KeyName>(v => ({ key: v.code, name: v.text }));
  }
}
