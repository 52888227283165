import {
  LicenceDto,
  LicenceSummaryDto,
  LicenceTypeDto,
  ProviderAssignLicenceDto
} from "core/models/licence";
import { guid } from "core/types/Guid";

import { AxiosInstance } from "@bps/http-client";

export class LicenceGateway {
  constructor(public api: AxiosInstance) {}

  public getLicences = async (): Promise<LicenceDto[]> =>
    (await this.api.get<LicenceDto[]>("licences")).data;

  public getLicenceToBeRenewed = async (): Promise<LicenceSummaryDto[]> =>
    (await this.api.get<LicenceSummaryDto[]>("licences/toBeRenewed")).data;

  public getLicenceToBeRemoved = async (): Promise<LicenceSummaryDto[]> =>
    (await this.api.get<LicenceSummaryDto[]>("licences/toBeRemoved")).data;

  public assignLicence = async (
    data: ProviderAssignLicenceDto
  ): Promise<LicenceDto> => {
    const response = await this.api.put(
      `licences/assign?userId=${data.userId}&licenceType=${data.licenceType}`
    );
    return { ...response.data };
  };

  public unassignLicence = async (licenceId: guid): Promise<LicenceDto> => {
    const response = await this.api.put(`licences/${licenceId}/remove`);
    return { ...response.data };
  };

  public getLicenceTypes = async (): Promise<LicenceTypeDto[]> =>
    (await this.api.get<LicenceTypeDto[]>("licenceTypes")).data;
}
