import Dashboard from "Dashboard";

import { routes } from "common/routes";
import { Authorized } from "core/auth/Authorized";
import {
  PermissionsOperator,
  PermissionsSpec,
  usePermissionsChecker
} from "core/auth/Permissions";
import { ComponentType, lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { useAsync } from "react-use";

import { NotFoundErrorBox, Overlay } from "@bps/fluent-ui";

import { MainLayout } from "./MainLayout";

interface LazyRoute {
  path: string;
  exact?: boolean;
  permissions?: PermissionsSpec;
  permissionsOperator?: PermissionsOperator;
  importComponent: () => Promise<{ default: ComponentType<any> }>;
}

const lazyRoutes: LazyRoute[] = [
  {
    path: `${routes.appointmentBooking.basePath}/*`,
    importComponent: () =>
      import(/* webpackChunkName: "booking"*/ "../modules/booking")
  },

  {
    path: `${routes.premierOnlineServices.basePath}/*`,
    importComponent: () =>
      import(
        /* webpackChunkName: "premierOnlineServices"*/ "../modules/premierOnlineServices"
      )
  }
];

// Simple wrapper component that renders the resolved lazy import
const LazyWrapper = ({ component: Component }) => <Component />;

export const Shell = () => {
  const hasPermissions = usePermissionsChecker();

  useAsync(async () => {
    if (!hasPermissions) return;

    const preloads = lazyRoutes
      .filter(def => hasPermissions(def.permissions, def.permissionsOperator))
      .map(def => def.importComponent());

    await Promise.all(preloads);
  }, [hasPermissions]);

  return (
    <MainLayout>
      <Suspense fallback={<Overlay styles={{ root: { zIndex: 100 } }} />}>
        <Routes>
          <Route path={routes.dashboard} element={<Dashboard />} />

          {lazyRoutes.map(route => (
            <Route
              key={route.path as string}
              path={route.path}
              element={
                <Authorized
                  permissions={route.permissions}
                  operator={route.permissionsOperator}
                >
                  <LazyWrapper component={lazy(route.importComponent)} />
                </Authorized>
              }
            />
          ))}

          <Route element={<NotFoundErrorBox showLogo />} />
        </Routes>
      </Suspense>
    </MainLayout>
  );
};
