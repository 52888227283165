import { Environment } from "core/Environment";

import { AuthenticationOptions } from "@bps/http-client";
import { IConfiguration } from "@microsoft/applicationinsights-web";

interface RuntimeConfig {
  appInsightsInstrumentationKey: string;
  googleTagManagerId: string;

  msal: AuthenticationOptions;

  locale: string;
  dateFormatPattern: string;

  bhbUrl: string;
  apiUrl: string;
  tgApiUrl: string;
  subscriptionUrl: string;
  PrOSHotJarSiteId: string;

  /** Environment field to drive feature toggles */
  environment: Environment;
  query: {
    staleTimeMillis: number;
  };
  appInsights?: IConfiguration;
}

export interface AppConfig
  extends Omit<RuntimeConfig, "appInsightsInstrumentationKey"> {
  app: {
    version: string;
    commitHash: string;
  };

  signalrBaseURL: string;
  notificationDuration: number;
}

// ----- Get the appConfig as loaded from public/config.js -----
declare global {
  interface Window {
    appConfig: RuntimeConfig;
  }
}

const { appInsightsInstrumentationKey, ...runtime } = window.appConfig;
// -------------------------------------------------------------

// ----- Merge with .env settings ------------------------------
export const config: AppConfig = {
  ...runtime,

  msal: {
    ...runtime.msal,
    postAuthenticateRedirectUrl: import.meta.env.PUBLIC_URL || "/"
  },

  appInsights: appInsightsInstrumentationKey
    ? { instrumentationKey: appInsightsInstrumentationKey }
    : undefined,

  app: {
    version: import.meta.env.VITE_VERSION as string,
    commitHash: import.meta.env.VITE_COMMIT_HASH as string
  },

  bhbUrl: import.meta.env.VITE_BHB_URL || runtime.bhbUrl,
  apiUrl: import.meta.env.VITE_API_URL || runtime.apiUrl,
  tgApiUrl: import.meta.env.VITE_TG_API_URL || runtime.tgApiUrl,
  subscriptionUrl:
    import.meta.env.VITE_SUBSCRIPTION_URL || runtime.subscriptionUrl,
  PrOSHotJarSiteId:
    import.meta.env.VITE_HOTJAR_SITEID || runtime.PrOSHotJarSiteId,
  signalrBaseURL: "/hubs",

  notificationDuration: 4000
};
// -------------------------------------------------------------
