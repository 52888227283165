import { useGateways } from "core/gateways-context";
import {
  cacheUpdateItemArray,
  cacheUpsertSingleItemInArray
} from "core/gateways/ReactQueryCacheHelpers";
import { LocationDto } from "core/models/location";
import { QueryOptions } from "core/ReactQueryProvider";
import { guid } from "core/types/Guid";
import { mapValues } from "lodash";
import {
  AppointmentTypeDto,
  AppointmentTypeSortOrderDto
} from "modules/booking/models";
import {
  AppointmentTypeCategoryDto,
  AppointmentTypeCategoryMappingDto,
  AppointmentTypeCategoryRequestDto
} from "modules/booking/models/appointmentTypeCategory";
import {
  PracticeNoticeDto,
  PracticeNoticeRequest
} from "modules/booking/models/practiceNotice.model";
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult
} from "react-query";

import { AxiosError } from "@bps/http-client";

import { ProviderCacheKeys } from "./ProviderHooks";

const CacheKeys = {
  LocationAppointmentTypes: "location-appointment-types",
  LocationExclusionPeriods: "location-exclusion-periods",
  LocationProviders: "location-providers",
  Locations: "locations",
  LocationNotices: "location-notices",
  LocationAppointmentTypeCategoryMapping: "location-categories"
};

function useCreateLocationsQuery<TResult>(
  options?: QueryOptions<LocationDto[], TResult>
) {
  const { locationApi } = useGateways();
  return useQuery(CacheKeys.Locations, locationApi.getLocations, options);
}

export const useLocationsQuery = useCreateLocationsQuery<LocationDto[]>;

export const useLocationQuery = (
  locationId: guid
): UseQueryResult<LocationDto | undefined> => {
  const options: QueryOptions<LocationDto[], LocationDto | undefined> = {
    select: (data: LocationDto[]) => data.find(l => l.id === locationId)
  };

  return useCreateLocationsQuery<LocationDto | undefined>(options);
};

export const useLocationAppointmentTypesQuery = (
  locationId: guid
): UseQueryResult<AppointmentTypeDto[]> => {
  const { locationApi } = useGateways();
  return useQuery<AppointmentTypeDto[]>(
    [CacheKeys.LocationAppointmentTypes, locationId],
    async () => await locationApi.getAppointmentTypesForLocation(locationId)
  );
};

export const useUpdateLocationMutation = () => {
  const queryClient = useQueryClient();
  const { locationApi } = useGateways();

  return useMutation<LocationDto, unknown, { location: LocationDto }>(
    ({ location }) => {
      return locationApi.updatePatchLocationPatch(
        // convert undefined values into null to remove it in patch
        mapValues(location, a => {
          return typeof a === "undefined" ? null : a;
        }) as Patch<LocationDto>
      );
    },
    {
      onSuccess: (location: LocationDto) => {
        cacheUpsertSingleItemInArray({
          queryClient,
          queryKey: CacheKeys.Locations,
          item: location
        });
      }
    }
  );
};

export const useUpdateLocationLogoMutation = () => {
  const { locationApi } = useGateways();
  return useMutation<string, unknown, { id: string; logo: File }>(
    ({ id, logo }) => locationApi.uploadLogo(id, logo)
  );
};

export const useUpdateAppointmentSortOrderMutation = () => {
  const queryClient = useQueryClient();
  const { locationApi } = useGateways();

  return useMutation<
    AppointmentTypeDto[],
    unknown,
    { locationId: string; reOrdered: AppointmentTypeSortOrderDto[] }
  >(
    ({ locationId, reOrdered }) =>
      locationApi.updateSortOrder(locationId, reOrdered),
    {
      onSuccess: (appointmentTypes: AppointmentTypeDto[], { locationId }) => {
        const queryKey = [CacheKeys.LocationAppointmentTypes, locationId];
        cacheUpdateItemArray(queryClient, queryKey, () => appointmentTypes);

        queryClient.invalidateQueries({
          queryKey: [
            CacheKeys.LocationAppointmentTypeCategoryMapping,
            locationId
          ]
        });
      }
    }
  );
};

export const useUpdateAppointmentTypeMutation = () => {
  const queryClient = useQueryClient();
  const { locationApi } = useGateways();

  return useMutation<
    AppointmentTypeDto,
    unknown,
    { locationId: string; appointmentType: AppointmentTypeDto }
  >(
    ({ locationId, appointmentType }) =>
      locationApi.updateAppointmentTypeForLocation(locationId, appointmentType),
    {
      onSuccess: (appointmentType: AppointmentTypeDto, { locationId }) => {
        if (
          !appointmentType.isAvailableExistingPatients &&
          !appointmentType.isAvailableNewPatients
        ) {
          queryClient.invalidateQueries(CacheKeys.LocationProviders);
          queryClient.invalidateQueries(
            ProviderCacheKeys.ProviderAppointmentTypes
          );
        }

        cacheUpsertSingleItemInArray({
          queryClient,
          queryKey: [CacheKeys.LocationAppointmentTypes, locationId],
          item: appointmentType
        });

        // Refetch cache for provider appointment types table
        queryClient.refetchQueries(ProviderCacheKeys.ProviderAppointmentTypes);

        // Refetch patient config cache for default on/off state for existing and new patients.
        queryClient.refetchQueries(ProviderCacheKeys.PatientConfig);

        queryClient.invalidateQueries({
          queryKey: [
            CacheKeys.LocationAppointmentTypeCategoryMapping,
            locationId
          ]
        });
      }
    }
  );
};

export const useLocationNoticesQuery = (
  locationId: guid
): UseQueryResult<PracticeNoticeDto[]> => {
  const { locationApi } = useGateways();
  return useQuery<PracticeNoticeDto[]>(
    [CacheKeys.LocationNotices, locationId],
    async () => await locationApi.getNoticesForLocation(locationId)
  );
};

export const useAddPracticeNoticeMutation = () => {
  const queryClient = useQueryClient();
  const { locationApi } = useGateways();
  return useMutation<PracticeNoticeDto, AxiosError, PracticeNoticeRequest>(
    request => locationApi.addNotice(request),
    {
      onSuccess: (updated: PracticeNoticeDto) => {
        const { locationId } = updated;

        cacheUpsertSingleItemInArray({
          queryClient,
          queryKey: [CacheKeys.LocationNotices, locationId],
          item: updated
        });
      }
    }
  );
};

export const useUpdatePracticeNoticeMutation = () => {
  const queryClient = useQueryClient();
  const { locationApi } = useGateways();
  return useMutation<PracticeNoticeDto, AxiosError, PracticeNoticeRequest>(
    request => {
      if (request.id) return locationApi.updateNotice(request.id, request);
      else {
        throw Error("Id is required to update the notice");
      }
    },
    {
      onSuccess: (updated: PracticeNoticeDto) => {
        const { locationId } = updated;

        cacheUpsertSingleItemInArray({
          queryClient,
          queryKey: [CacheKeys.LocationNotices, locationId],
          item: updated
        });
      }
    }
  );
};

export const useDeletePracticeNoticeMutation = () => {
  const queryClient = useQueryClient();
  const { locationApi } = useGateways();

  return useMutation<void, AxiosError, { locationId: guid; noticeId: guid }>(
    async ({ locationId, noticeId }) => {
      await locationApi.deleteNotice(locationId, noticeId);
    },
    {
      onSuccess: (_, { locationId, noticeId }) => {
        cacheUpdateItemArray(
          queryClient,
          [CacheKeys.LocationNotices, locationId],
          cached => cached.filter(prev => prev.id !== noticeId)
        );
      }
    }
  );
};

export const useLocationNoticeQuery = (
  locationId: guid,
  noticeId?: guid,
  refetch?: boolean | "always"
): UseQueryResult<PracticeNoticeDto | undefined> => {
  const { locationApi } = useGateways();
  return useQuery<PracticeNoticeDto | undefined>(
    [locationId, noticeId],
    async () => {
      if (noticeId)
        return await locationApi.getNoticeForLocation(locationId, noticeId);
      return undefined;
    },
    { enabled: !!noticeId, refetchOnMount: refetch }
  );
};

export const useAddAppointmentTypeCategoryMutation = (locationId: guid) => {
  const { locationApi } = useGateways();
  const queryClient = useQueryClient();
  return useMutation<
    AppointmentTypeCategoryDto,
    AxiosError,
    AppointmentTypeCategoryRequestDto
  >(request => locationApi.addAppointmentTypeCategory(locationId, request), {
    onSuccess: createdCategory => {
      queryClient.setQueryData(
        [CacheKeys.LocationAppointmentTypeCategoryMapping, locationId],
        (oldData: AppointmentTypeCategoryMappingDto) => {
          const newAppointmentTypes = oldData.appointmentTypes.filter(
            at =>
              !createdCategory.appointmentTypes.find(cat => at.id === cat.id)
          );

          const newCategories = [...oldData.categories, createdCategory];

          return {
            appointmentTypes: newAppointmentTypes,
            categories: newCategories
          };
        }
      );
    }
  });
};

export const useUpdateAppointmentTypeCategoryMutation = (locationId: guid) => {
  const { locationApi } = useGateways();
  const queryClient = useQueryClient();
  return useMutation<
    AppointmentTypeCategoryDto,
    AxiosError,
    {
      id: string;
      request: AppointmentTypeCategoryRequestDto;
      refreshCache?: boolean;
    }
  >(
    ({ id, request }) =>
      locationApi.updateAppointmentTypeCategory(locationId, id, request),
    {
      onSuccess: (updatedCategory, { refreshCache = true }) => {
        refreshCache &&
          queryClient.setQueryData(
            [CacheKeys.LocationAppointmentTypeCategoryMapping, locationId],
            (oldData: AppointmentTypeCategoryMappingDto) => {
              const oldCategory = oldData.categories.find(
                category =>
                  category.appointmentTypeCategoryId ===
                  updatedCategory.appointmentTypeCategoryId
              );

              // Any appt types removed from the category in the update
              const removedAppointmentTypes =
                oldCategory?.appointmentTypes.filter(
                  a =>
                    !updatedCategory.appointmentTypes.find(b => b.id === a.id)
                ) ?? [];

              const uncategorisedAppointmentTypes = [
                // Filters out any appt types added to the category in the update
                ...oldData.appointmentTypes.filter(
                  at =>
                    !updatedCategory.appointmentTypes.find(x => x.id === at.id)
                ),
                ...removedAppointmentTypes
              ];

              const newCategories = [
                ...oldData.categories.filter(
                  category =>
                    category.appointmentTypeCategoryId !==
                    updatedCategory.appointmentTypeCategoryId
                ),
                updatedCategory
              ];

              const newData: AppointmentTypeCategoryMappingDto = {
                categories: newCategories,
                appointmentTypes: uncategorisedAppointmentTypes
              };

              return newData;
            }
          );
      }
    }
  );
};

export const useAppointmentTypeCategoryMappingQuery = (locationId: guid) => {
  const { locationApi } = useGateways();
  return useQuery<AppointmentTypeCategoryMappingDto>(
    [CacheKeys.LocationAppointmentTypeCategoryMapping, locationId],
    () => locationApi.getAppointmentTypeCategoryMapping(locationId)
  );
};

export const useDeleteAppointmentTypeCategoryMutation = (locationId: guid) => {
  const { locationApi } = useGateways();
  const queryClient = useQueryClient();

  return useMutation<void, AxiosError, guid>(
    async id => await locationApi.deleteAppointmentTypeCategory(locationId, id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [
            CacheKeys.LocationAppointmentTypeCategoryMapping,
            locationId
          ]
        });

        // Appointment types in a deleted category have their sortOrder to null updated by the backend
        queryClient.invalidateQueries({
          queryKey: [CacheKeys.LocationAppointmentTypes, locationId]
        });
      }
    }
  );
};
