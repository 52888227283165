import { action, makeObservable, observable, runInAction } from "mobx";
import { ReactNode } from "react";

export type NotificationType = "info" | "success" | "error" | "warning";

export interface Notification {
  message: ReactNode;
  type: NotificationType;
  key: string;
  duration?: number;
}

export class FeedbackStore {
  private keyCounter = 0;

  constructor(public config = { notificationDuration: 4000 }) {
    makeObservable(this, {
      close: action,
      notify: action
    });
  }

  notifications = observable<Notification>([], { deep: false });

  close = (key: string) => {
    const notification = this.notifications.find(x => x.key === key);
    if (notification) {
      return this.notifications.remove(notification);
    }
    return false;
  };

  notify = (args: Omit<Notification, "key">): string => {
    const notification = { ...args, key: `${this.keyCounter++}` };
    this.notifications.push(notification);
    setTimeout(() => {
      // ⚠️ remove will not work unless notifications is created with deep: false option
      runInAction(() => {
        this.notifications.remove(notification);
      });
    }, args.duration || this.config.notificationDuration);
    return notification.key;
  };

  error = (message: ReactNode, options: { duration?: number } = {}) =>
    this.notify({
      message,
      type: "error",
      duration: !options.duration ? 3000 : options.duration
    });

  info = (message: ReactNode, options: { duration?: number } = {}) =>
    this.notify({
      message,
      type: "info",
      duration: options.duration
    });

  success = (message: ReactNode, options: { duration?: number } = {}) =>
    this.notify({
      message,
      type: "success",
      duration: options.duration
    });

  warn = (message: ReactNode, options: { duration?: number } = {}) =>
    this.notify({
      message,
      type: "warning",
      duration: options.duration
    });
}
