import { config } from "core/config";
import { CoreStore, FeedbackStore } from "core/stores";
import { UserPreferencesStore } from "core/stores/UserPreferenceStore";
import { createContext, PropsWithChildren, useContext } from "react";

interface Stores {
  core: CoreStore;
  feedback: FeedbackStore;
  userPreferenceStore: UserPreferencesStore;
}

const stores: Stores = {
  core: new CoreStore(),
  feedback: new FeedbackStore({
    notificationDuration: config.notificationDuration
  }),
  userPreferenceStore: new UserPreferencesStore()
};

export const StoresContext = createContext<Stores>(stores);

export const StoresProvider = ({ children }: PropsWithChildren<unknown>) => {
  return (
    <StoresContext.Provider value={stores}>{children}</StoresContext.Provider>
  );
};

export const useStores = () => useContext(StoresContext);
export const useCoreStore = () => useStores().core;
export const useFeedbackStore = () => useStores().feedback;
export const useUserPreferencesStore = () => useStores().userPreferenceStore;
