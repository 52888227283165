import { navBarActionButtonStyles } from "layout/NavBar";
import { useLocation, useNavigate } from "react-router-dom";

import { ActionButton, IContextualMenuItem, useTheme } from "@bps/fluent-ui";

export const Help = () => {
  const theme = useTheme();
  const currentLocation = useLocation();
  const navigate = useNavigate();

  const menuItemSelected = (
    ev?: React.MouseEvent<HTMLButtonElement>,
    item?: IContextualMenuItem
  ) => {
    if (item?.data === currentLocation.pathname) {
      // Appcues is loaded by Google Tag Manager, it does not appear on the Window, therefore using a @ts-ignore tag here.
      // @ts-ignore
      Appcues.show(item.key);
    } else {
      navigate(`${item?.data}?appcue=${item?.key}`);
    }
  };

  const appCuesItems: IContextualMenuItem[] = [
    {
      key: "1c78ca6c-1df4-45f4-ad0c-9a55c8263c02",
      data: "/booking/practice-profile",
      text: "Practice Profile",
      onClick: menuItemSelected
    },
    {
      key: "650d67cf-fa05-48a2-9e4e-4a4514dcec62",
      data: "/booking/online-settings",
      text: "Online Settings",
      onClick: menuItemSelected
    },
    {
      key: "d5372743-3c00-40cf-aa80-d4c35b48e5c6",
      data: "/booking/appointment-types",
      text: "Appointment Types",
      onClick: menuItemSelected
    },
    {
      key: "4c7553f1-2d61-418e-b500-00969332aadb",
      data: "/booking/providers",
      text: "Providers",
      onClick: menuItemSelected
    },
    {
      key: "b645abf9-204c-4d03-bcad-6741509e04c4",
      data: "/booking/exclusion-periods",
      text: "Exclusion Periods",
      onClick: menuItemSelected
    }
  ];

  const items: IContextualMenuItem[] = [
    {
      key: "interactiveGuide",
      text: "Interactive guide",
      subMenuProps: { items: appCuesItems }
    },
    {
      key: "knowledgeBase",
      text: "Knowledge base",
      onClick: () => {
        window.open(
          "https://kb.bpsoftware.net/bh/Bookings/index.htm",
          "_blank"
        );
      }
    }
  ];

  return (
    <ActionButton
      iconProps={{ iconName: "PublishCourse" }}
      text="Help"
      styles={navBarActionButtonStyles(theme)}
      menuProps={{
        shouldFocusOnMount: true,
        items
      }}
    />
  );
};
