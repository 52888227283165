export const routes = {
  appointmentBooking: {
    basePath: "booking",
    locationEdit: "/booking/practice-profile",
    onlineSettings: "/booking/online-settings"
  },

  premierOnlineServices: {
    basePath: "/premier-online-services"
  },
  dashboard: ""
};
