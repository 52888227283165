import { LocationDto } from "core/models/location";
import { CONTENT_TYPE_HEADER, IF_MATCH } from "core/types/api.constants";
import { guid } from "core/types/Guid";
import {
  AppointmentTypeDto,
  AppointmentTypeSortOrderDto
} from "modules/booking/models";
import {
  AppointmentTypeCategoryDto,
  AppointmentTypeCategoryMappingDto,
  AppointmentTypeCategoryRequestDto
} from "modules/booking/models/appointmentTypeCategory";
import {
  PracticeNoticeDto,
  PracticeNoticeRequest
} from "modules/booking/models/practiceNotice.model";

import { AxiosInstance } from "@bps/http-client";

interface UploadLogoResponse {
  link: string;
}

export class LocationGateway {
  constructor(private api: AxiosInstance) {}

  public getLocations = async (): Promise<LocationDto[]> =>
    (await this.api.get<LocationDto[]>("location")).data;

  public getLocationById = async (locationId: guid): Promise<LocationDto> =>
    (await this.api.get<LocationDto>(`location/${locationId}`)).data;

  public getAppointmentTypesForLocation = async (
    locationId: guid
  ): Promise<AppointmentTypeDto[]> =>
    (
      await this.api.get<AppointmentTypeDto[]>(
        `location/${locationId}/appointmenttype`
      )
    ).data;

  public updateLocation = async (location: LocationDto) => {
    const { data } = await this.api.put<LocationDto>("location", location, {
      headers: {
        eTag: location.eTag
      }
    });

    return data;
  };

  public updatePatchLocationPatch = async (
    location: Patch<LocationDto>
  ): Promise<LocationDto> => {
    const { id, eTag, ...data } = location;
    const response = await this.api.patch<LocationDto>(
      `/location/${id}`,
      data,
      {
        headers: {
          [IF_MATCH]: location.eTag
        }
      }
    );
    return { ...response.data };
  };

  public uploadLogo = async (
    locationId: string,
    logo: File
  ): Promise<string> => {
    const formData = new FormData();
    formData.append("FileData", logo);

    const { data } = await this.api.post<UploadLogoResponse>(
      `location/${locationId}/uploadLogo`,
      formData,
      {
        headers: {
          [CONTENT_TYPE_HEADER]: "multipart/form-data"
        }
      }
    );

    return data.link;
  };

  public updateAppointmentTypeForLocation = async (
    locationId: string,
    appointmentType: AppointmentTypeDto
  ): Promise<AppointmentTypeDto> => {
    const { data } = await this.api.put<AppointmentTypeDto>(
      `location/${locationId}/appointmenttype/${appointmentType.id}`,
      appointmentType,
      {
        headers: {
          eTag: appointmentType.eTag
        }
      }
    );

    return data;
  };

  public updateSortOrder = async (
    locationId: string,
    sortRequest: AppointmentTypeSortOrderDto[]
  ) => {
    const { data } = await this.api.put(
      `location/${locationId}/appointmenttype/sort`,
      sortRequest
    );

    return data;
  };

  public getNoticesForLocation = async (
    locationId: guid
  ): Promise<PracticeNoticeDto[]> => {
    return (
      await this.api.get<PracticeNoticeDto[]>(
        `location/${locationId}/practicenotice`
      )
    ).data;
  };

  public getNoticeForLocation = async (
    locationId: guid,
    noticeId: guid
  ): Promise<PracticeNoticeDto> =>
    (
      await this.api.get<PracticeNoticeDto>(
        `location/${locationId}/practicenotice/${noticeId}`
      )
    ).data;

  public addNotice = async (
    practiceNotice: PracticeNoticeRequest
  ): Promise<PracticeNoticeDto> => {
    const { data } = await this.api.post<PracticeNoticeDto>(
      `location/${practiceNotice.locationId}/practicenotice`,
      practiceNotice
    );
    return data;
  };

  public deleteNotice = async (
    locationId: string,
    noticeId: string
  ): Promise<void> => {
    await this.api.delete<void>(
      `location/${locationId}/practicenotice/${noticeId}`
    );
  };

  public updateNotice = async (
    id: guid,
    practiceNotice: PracticeNoticeRequest
  ): Promise<PracticeNoticeDto> => {
    const { data } = await this.api.put<PracticeNoticeDto>(
      `location/${practiceNotice.locationId}/practicenotice/${id}`,
      practiceNotice,
      {
        headers: {
          eTag: practiceNotice.eTag
        }
      }
    );

    return data;
  };

  public addAppointmentTypeCategory = async (
    locationId: string,
    request: AppointmentTypeCategoryRequestDto
  ): Promise<AppointmentTypeCategoryDto> => {
    const { data } = await this.api.post<AppointmentTypeCategoryDto>(
      `location/${locationId}/appointmenttypecategory`,
      request
    );
    return data;
  };

  public updateAppointmentTypeCategory = async (
    locationId: string,
    id: string,
    request: AppointmentTypeCategoryRequestDto
  ): Promise<AppointmentTypeCategoryDto> => {
    const { data } = await this.api.put<AppointmentTypeCategoryDto>(
      `location/${locationId}/appointmenttypecategory/${id}`,
      request
    );
    return data;
  };

  public getAppointmentTypeCategoryMapping = async (
    locationId: string
  ): Promise<AppointmentTypeCategoryMappingDto> => {
    const { data } = await this.api.get<AppointmentTypeCategoryMappingDto>(
      `location/${locationId}/appointmenttypecategory`
    );
    return data;
  };

  public deleteAppointmentTypeCategory = async (
    locationId: string,
    id: string
  ): Promise<void> => {
    await this.api.delete<void>(
      `location/${locationId}/appointmenttypecategory/${id}`
    );
  };
}
