import {
  checkLocationMultiAppt,
  getCombinedAppointmentTypeData
} from "common/utils";
import {
  useLocationAppointmentTypesQuery,
  useLocationQuery
} from "core/gateways/practice/LocationHooks";
import { useLocationProvidersQuery } from "core/gateways/practice/ProviderHooks";
import { useAppointmentTypesTG } from "core/gateways/tenant";
import { observer } from "mobx-react-lite";

import { MultiApptWarningMessageBar } from "./appointment-types-multi-appt-warning-message-bar";

interface MultiApptWarningProps {
  locationId: string;
}

export const MultiApptWarning = observer(
  ({ locationId }: MultiApptWarningProps) => {
    const { data: location } = useLocationQuery(locationId);
    const { data: providers } = useLocationProvidersQuery(locationId);
    const { data: appointmentTypes } =
      useLocationAppointmentTypesQuery(locationId);

    const { data: appointmentTypesTG } = useAppointmentTypesTG({
      appointmentTypeIds: appointmentTypes?.map(at => at.id) ?? []
    });
    if (
      !(
        providers?.length &&
        appointmentTypes?.length &&
        appointmentTypesTG?.length &&
        location
      )
    )
      return null;

    // Combine tenant gateway's appointment type for group booking data.
    const combinedAppointmentTypeData = getCombinedAppointmentTypeData(
      appointmentTypes!,
      appointmentTypesTG!
    );

    if (!combinedAppointmentTypeData) return null;

    // Check Location Customised appointment types
    const isLocationMultiApptCustomised = combinedAppointmentTypeData.some(at =>
      checkLocationMultiAppt(at)
    );

    // Check Provider Customised appointment types
    const isProviderMultiApptCustomised = providers.some(p => {
      const combinedAppointmentTypeData = getCombinedAppointmentTypeData(
        p.appointmentTypes!,
        appointmentTypesTG!
      );
      return combinedAppointmentTypeData!.some(pat => {
        return checkLocationMultiAppt(pat);
      });
    });

    return (
      <>
        {(isLocationMultiApptCustomised || isProviderMultiApptCustomised) && (
          <MultiApptWarningMessageBar />
        )}
      </>
    );
  }
);
