import { FormProps } from "common/ui-components/form/Form.types";
import { debounce } from "lodash";
import { PropsWithChildren, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { FieldValues } from "react-hook-form/dist/types/fields";

import { Overlay } from "@bps/fluent-ui";

interface FieldsAutoSaveWrapperProps<FieldValues>
  extends Pick<
    FormProps<FieldValues>,
    "onSubmit" | "autoSave" | "autoSaveDelay"
  > {
  className: string;
}
export const FieldsAutoSaveWrapper = <
  FormValues extends FieldValues = FieldValues
>({
  children,
  className,
  onSubmit,
  autoSave,
  autoSaveDelay = 500
}: PropsWithChildren<FieldsAutoSaveWrapperProps<FormValues>>) => {
  const { formState } = useFormContext<FormValues>();
  const form = useFormContext<FormValues>();

  const handleSubmit = () =>
    form.handleSubmit(() => onSubmit(form.getValues(), form))();

  const autoSaveHandler = debounce(handleSubmit, autoSaveDelay);

  useEffect(() => {
    if (autoSave) {
      const { unsubscribe } = form.watch((_v, info) => {
        // when user interacts with from controls
        const isManualFormChangeAction = info?.type === "change";
        // when we use form api to set a form value, e.g. setValue("name", value)
        const isProgrammaticallyFormChange =
          (info["values"] && info?.name) || !info["values"];

        if (
          info?.name &&
          (isManualFormChangeAction || isProgrammaticallyFormChange)
        ) {
          autoSaveHandler();
          unsubscribe();
        }
      });
      return () => unsubscribe();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.formState.submitCount]);

  return (
    <div className={className}>
      {!autoSave && formState.isSubmitting && (
        <Overlay styles={{ root: { zIndex: 100 } }} />
      )}
      {children}
    </div>
  );
};
