import { GoogleTagManagerEvents } from "common/gtm.enums";
import { useCoreStore } from "common/StoresProvider";
import { UserDto } from "core/models";
import { useEffect } from "react";

export const useGtmAuthorisation = (
  user: UserDto | undefined,
  tenantId: string | undefined
) => {
  const { setCurrentUserId } = useCoreStore();
  useEffect(() => {
    if (gtm && user && tenantId) {
      gtm.push({
        event: GoogleTagManagerEvents.Authenticated,
        userId: user.id,
        tenantId,
        roles: user.roles.map(r => r.roleCode).join(",")
      });
      setCurrentUserId(user.id);
    }
  }, [user, tenantId, setCurrentUserId]);
};
