import {
  ResolverError,
  ResolverSuccess
} from "react-hook-form/dist/types/resolvers";

import { ValidateCallback } from "../types/validate.types";

//A resolver to parse error messages into react-hooks-form ResolveResults format
export const validationResolver = async <FormValues extends {}>(
  validateCallback: ValidateCallback<FormValues>,
  values: FormValues
): Promise<ResolverSuccess<FormValues> | ResolverError<FormValues>> => {
  const resolverFieldErrors = validateCallback(values);
  return { values, errors: resolverFieldErrors };
};
